<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/erflog/logo.png" alt="">
        <!-- <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0  mobile_bg_main"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            src="@/assets/images/erflog/login.jpg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->       
      <!-- Login--> 
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        > 

        <b-card-title class="mb-2 font-weight-bold" title-tag="h2">
          Sign in
        </b-card-title>
        <b-card-text class="mb-2 bold_text">
          Sign in if you have an account in here 
        </b-card-text> 
        
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >           
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
                class="custom_placeholder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                    @keyup="credentialError.email = ''"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if='credentialError.email !== ""'>{{credentialError.email}}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Enter Password"
                      @keyup="credentialError.pass = ''"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if='credentialError.pass !== ""'>{{credentialError.pass}}</small>
                </validation-provider>
              </b-form-group>            

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isLogging"
              >
                <span v-if="!isLogging">
                  Log in
                </span>
                <span v-else>
                  <b-spinner small/>
                </span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, 
  BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections } from '@/utils/firebaseCollection.js';
import firebase from "@/utils/firebaseInit";
import 'firebase/firestore';

const db = firebase.firestore()

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {      
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      isLogging:false,
      credentialError:{
        email:'',
        pass:''
      }     
    }
  },  
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async login() {
      this.isLogging = true;
      try {
        const success = await this.$refs.loginForm.validate();
        if (success) {
          this.credentialError.email = '';
          this.credentialError.pass = '';

          const { user } = await firebase.auth().signInWithEmailAndPassword(this.userEmail, this.password);
          getQuery.getDataFromRootCollectionWithDocIDV2(dbCollections.USERS, user.uid,async(res)=>{
            if (Object.keys(res).length <= 0) {
              this.isLogging=false
              this.handleLoginFailure('Invalid user credentials');
            } else {
              if (res.email === this.userEmail || res.personalEmail === this.userEmail || res.companyEmail === this.userEmail) {
                if (res.isDeleted === false) {
                  if (res.role === 'employee') {
                    const docData = await db.collection(dbCollections.USERROLES).doc(res.employeeRole.id).get();
                    localStorage.setItem('userAccess', JSON.stringify(docData.data().assignModules));
                  }
                  let tempObj = {};
                  if (res.role === 'admin') {
                    tempObj = {
                      id: res.id,
                      fullName: res.name,
                      username: res.name,
                      email: res.email,
                      role: res.role,
                      password: this.password,
                      avatar: 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/DefaultUser%2FDefaultUser.png?alt=media&token=1b92ac0f-1395-4062-9aa2-59f126ccf5e9',
                      ability: [
                        {
                          action: 'manage',
                          subject: 'all',
                        },
                      ],
                      extras: {
                        eCommerceCartItemsCount: 5,
                      },
                    };
                  } else if (res.role === 'student') {
                    tempObj = {
                      id: res.id,
                      fullName: res.name,
                      username: res.name,
                      email: res.email,
                      role: res.role,
                      password: this.password,
                      avatar: res.imageUrl ? res.imageUrl : 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/DefaultUser%2FdefaultUser.jpg?alt=media&token=c481d71c-8ec8-449d-afe7-6a5d54c4560f',
                      ability: [
                        {
                          action: 'read',
                          subject: 'ACL',
                        },
                        {
                          action: 'read',
                          subject: 'Auth',
                        }
                      ],
                      extras: {
                        eCommerceCartItemsCount: 5,
                      },
                    };
                  } else if (res.role === 'employee') {
                    let mainEmail = res.personalEmail;
                    if (res.companyEmail === this.userEmail) {
                      mainEmail = res.companyEmail;
                    }
                    tempObj = {
                      id: res.id,
                      fullName: res.name,
                      username: res.name,
                      email: mainEmail,
                      role: res.role,
                      password: this.password,
                      avatar: res.imageUrl ? res.imageUrl : 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/DefaultUser%2FdefaultUser.jpg?alt=media&token=c481d71c-8ec8-449d-afe7-6a5d54c4560f',
                      ability: [
                        {
                          action: 'read',
                          subject: 'EAC',
                        },
                        {
                          action: 'write',
                          subject: 'EAC',
                        },
                        {
                          action: 'read',
                          subject: 'Auth',
                        },
                      ],
                      extras: {
                        eCommerceCartItemsCount: 5,
                      },
                    };
                  }
    
                  localStorage.setItem('currentUser', JSON.stringify(tempObj));
    
                  const response = await useJwt.login({
                    email: this.userEmail,
                    password: this.password,
                  });
    
                  const { userData } = response.data;
                  useJwt.setToken(response.data.accessToken);
                  useJwt.setRefreshToken(response.data.refreshToken);
                  localStorage.setItem('userData', JSON.stringify(userData));
                  this.$ability.update(userData.ability);
    
                  const routeLocation = getHomeRouteForLoggedInUser(userData.role.toLowerCase());
                  // this.$root.$emit('showNotificationEnabledMessage');
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  });
                  setTimeout(() => {
                    this.$router.go(routeLocation).then(()=>{
                        this.isLogging = false;
                        this.$root.$emit('setVerticalTab');
                    })
                  },500);
                }
              }
            }
          });

        } else {
          this.isLogging = false;
        }
      } catch (error) {
        console.error(error);
        this.isLogging = false;
        if (error.code) {
          const msg = error.code.replace('auth/', '');
          this.handleLoginFailure(`${msg === 'user-disabled' ? 'User is Inactive' : msg}`);
        } else {
          this.handleLoginFailure('Invalid user credentials');
        }
      }
    },
    handleLoginFailure(errorMessage,type=false) {
      if(type == false && errorMessage == 'user-disabled') {
        this.$refs.loginForm.setErrors({
          email: errorMessage,
          pass: errorMessage,
        });
      }
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: errorMessage,
          icon: 'CoffeeIcon',
          variant: 'danger',
        },
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
